import React from 'react';
// Types
import { AnswerObject } from '../App';
// Styles
import { Wrapper, ButtonWrapper } from './QuestionCard.styles';

type Props = {
    question: string;
    answers: string[];
    callback: (e: React.MouseEvent<HTMLButtonElement>) => void;
    userAnswer: AnswerObject | undefined;
    questionNumber: number;
    totalQuestions: number;
}

const QuestionCard: React.FC<Props> = ({
    question, 
    answers, 
    callback, 
    userAnswer, 
    questionNumber, 
    totalQuestions
}) => (
<Wrapper>
    <p className="number">
        Question: {questionNumber} / {totalQuestions}
    </p>

    <p dangerouslySetInnerHTML={{ __html: question }} /> 
    <div>
        {answers.map( answer => ( 
            <ButtonWrapper 
                key={answer}
                correct={userAnswer?.correctAnswer === answer}  // This is called "optional chaining" which allows us to check the value
                userClicked={userAnswer?.answer === answer}
                >
                {/* Alternative way to represent a boolean value for the "userAnswer" value
                <button disabled={userAnswer ? true : false} value={answer} onClick={callback} >  */}
                <button disabled={!!userAnswer} value={answer} onClick={callback} >
                    <span dangerouslySetInnerHTML={{__html: answer}} /> 
                </button>
            </ButtonWrapper>
        ))}
    </div>
</Wrapper>
);

export default QuestionCard;
