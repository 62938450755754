import React, { useState } from 'react';
import { fetchQuizQuestions } from './API';
// Components
import QuestionCard from './components/QuestionCard';
// Types
import { QuestionsState, Difficulty } from './API';
// Styles
import { GlobalStyle, Wrapper } from './App.styles';

export type AnswerObject = {
  question: string;
  answer: string;
  correct: boolean;
  correctAnswer: string;
}

const TOTAL_QUESTIONS = 10;

const App = () => {

  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<QuestionsState[]>([]);
  const [number, setNumber] = useState(0);
  const [userAnswers, setUserAnswers] = useState<AnswerObject[]>([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(true);
  const [difficulty, setDifficulty] = useState<Difficulty>(Difficulty.EASY);

//console.log(questions);

  const startTrivia = async () => {
    setLoading(true);
    setGameOver(false);

    const newQuestions = await fetchQuizQuestions(
      TOTAL_QUESTIONS,
      difficulty
    );

    setQuestions(newQuestions);
    setScore(0);
    setUserAnswers([]);
    setNumber(0);
    setLoading(false);
  };

  const checkAnswer = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!gameOver){
      // User answers
      const answer = e.currentTarget.value;
      // Check answer against correct answer 
      const correct = questions[number].correct_answer === answer;
      // Add score if answer is correct
      if (correct) setScore(prev => prev + 1);
      // Save answer in the array for user answers
      const answerObject = {
        question: questions[number].question,
        answer, // new from ES6 if the value is the same as the object param name we can skip the ": answer"
        correct,  // new from ES6 if the value is the same as the object param name we can skip the ": correct"
        correctAnswer: questions[number].correct_answer
      };
      setUserAnswers((prev) => [...prev, answerObject]);

    }
  }

  const nextQuestion = () => {
    // Move on to the next question if not the last question
    const nextQuestion = number + 1;

    if ( nextQuestion === TOTAL_QUESTIONS){
      setGameOver(true);
    }else{
      setNumber(nextQuestion);
    }

  }

  const handleChangeDifficulty = (level: Difficulty) => {
    setDifficulty(level);
  }

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <h1>FUN TRIVIA</h1>
        
        {gameOver || userAnswers.length === TOTAL_QUESTIONS ? (
        <label>
          <div>
            <input type="radio" value="EASY"   name="level" onClick={e => handleChangeDifficulty(Difficulty.EASY)}/>EASY
            <input type="radio" value="MEDIUM" name="level" onClick={e => handleChangeDifficulty(Difficulty.MEDIUM)}/>MEDIUM
            <input type="radio" value="HARD"   name="level" onClick={e => handleChangeDifficulty(Difficulty.HARD)}/>HARD
          </div>
        </label>
        ) : null}

        {gameOver || userAnswers.length === TOTAL_QUESTIONS ? (
          <button className="start" onClick={startTrivia}>
            Start 
          </button>
        ) : null}
        { !gameOver ? <p className="score">Score: {score}</p> : null } 

        {gameOver || userAnswers.length === TOTAL_QUESTIONS ? (
          "You are really good at this game"
        ) : null }
        
        { loading && <p>Loading Questions...</p>}
        
        { !loading && !gameOver && (
        <QuestionCard 
          questionNumber={number + 1}
          totalQuestions={TOTAL_QUESTIONS}
          question={questions[number].question}
          answers={questions[number].answers}
          userAnswer={userAnswers ? userAnswers[number] : undefined}
          callback={checkAnswer}
        />
        )}
        
        {!gameOver && !loading && userAnswers.length === number + 1 && number !== TOTAL_QUESTIONS - 1 ? (
          <button className="next" onClick={nextQuestion}>
            Next Question
          </button>
        ) : null }
        
      </Wrapper>
    </>
  );
}

export default App;
